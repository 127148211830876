import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatalayerService } from 'src/services/datalayer.service';

@Component({
  selector: 'app-cps-global-nav-hamburger',
  templateUrl: './cps-global-nav-hamburger.component.html',
  styleUrls: ['./cps-global-nav-hamburger.component.scss']
})
export class CpsGlobalNavHamburgerComponent implements OnInit {

  public hamHideShow: boolean;
  public drugsCollapse: boolean;
  public toolsCollapse: boolean;
  public resourcesCollapse: boolean;

  public cpsnLink : string;

  public drugsMenu = [
    { "label" : "global-nav.whatnew", "link" : "whatsNew"},
    { "label" : "global-nav.monoUpdate", "link" : "monoUpdates"},
    { "label" : "global-nav.cpsMono", "link" : "monographs"},
    { "label" : "global-nav.medDevices", "link" : "medicalDevices"},
    { "label":  "global-nav.genericIndex", "link": "genericIndex" },
    { "label" : "global-nav.pma", "link" : "productsMinorAils"},
    { "label" : "global-nav.clinInfo", "link" : "clinInfo"},
    { "label" : "global-nav.tc", "link" : "tc"},
    { "label" : "global-nav.tcma", "link" : "minorAils"},
    { "label" : "global-nav.ifp", "link" : "patientMedInfo"},
    { "label" : "global-nav.ma_pi", "link" : "patientInfoMA"},
   
  ];

  public toolMenu = [
    { "label" : "global-nav.pillid", "link" : "pillid"},
    { "label" : "global-nav.drugAdminFood", "link" : "drugAdminFood"},
    { "label" : "global-nav.drugAdminGrape", "link" : "drugAdminGrape"},
    { "label" : "global-nav.renalCalc", "link" : "renalfunction"},
    { "label" : "global-nav.bodyCalc", "link" : "bodysurface"},
    { "label" : "global-nav.lexiNoHyphen", "link" : "lexi"}
  ];

  public resourceMenu = [
    { "label" : "global-nav.hcAdvisories", "link" : "hcAlert"},
    { "label" : "global-nav.hcReport", "link" : "hcReport"},
    { "label" : "global-nav.medAbbrev", "link" : "abbrevs"},
    { "label" : "global-nav.microAbbrev", "link" : "microOrgs"},
    { "label" : "global-nav.poisonControl", "link" : "externalPoisonControl"},
    { "label" : "global-nav.manuDir", "link" : "manufacturers"},
    { "label" : "global-nav.healthOrg", "link" : "healthOrgs"},
    { "label" : "global-nav.publicDrugPrograms", "link" : "publicDrugProg"}
  ];

  constructor(public translationService: TranslateService, private datalayerService: DatalayerService) {
    
    this.hamHideShow = false;
    this.drugsCollapse = true;
    this.toolsCollapse = true;
    this.resourcesCollapse = true;

  }

  ngOnInit(): void {
    this.cpsnLink = "https://www.cpsnotifications.ca";

    const container = document.getElementById('globalNavHam-container-id');

    document.addEventListener('click', ( event ) => {
      if (container !== <HTMLInputElement>event.target && !container.contains(<HTMLInputElement>event.target)) {    
        this.hamburgerMenuHideShow(false);
        this.drugsCollapse = true;
        this.toolsCollapse = true;
        this.resourcesCollapse = true;
      }
    });
  }

  hamburgerMenuHideShow (value?: boolean): void {

    if (value !== undefined) {
      this.hamHideShow = value;
    } else if (this.hamHideShow) {
      this.hamHideShow = false;
    } else {
      this.hamHideShow = true;
    }
  }

  onCategoryClicked(categoryName: string, subcategoryName: string): void {
    this.datalayerService.globalNavCategoryClickedEvent(categoryName, subcategoryName);
  }

  onSubcategoryClicked(categoryName: string, subcategoryName: string): void {
    this.hamburgerMenuHideShow(false);
    this.datalayerService.globalNavSubcategoryClickedEvent(categoryName, subcategoryName);
  }

  isExternalLink(link: string): boolean {
    return link.indexOf('external') > -1;
  }
}
