<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>

    <div class="doc-content-wrapper">

        <!-- Skeleton-loader for desktop view -->
        <div class="skeleton-first-wrapper" *ngIf="!contentLoaded && !mobileView">
            <div>
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '230px', height: '500px', 'border-radius': '10px' }">
                </ngx-skeleton-loader>
            </div>
            <div class="skeleton-first-wrapper-items">
                <div class="skeleton-title">
                    <ngx-skeleton-loader
                        [theme]="{width: '350px', height: '35px', 'border-radius': '10px', 'margin-left': '-5px'}"></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader appearance="circle"
                        [theme]="{ width: '850px', 'border-radius': '10px', height: '80px', 'margin-bottom': '10px' }">
                    </ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader
                        [theme]="{width: '500px', 'border-radius': '10px', height: '35px', 'margin-left': '5px'}"></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader appearance="circle"
                        [theme]="{ width: '950px', 'border-radius': '10px', height: '280px' }">
                    </ngx-skeleton-loader>
                </div>
            </div>        
        </div>

        <div class="skeleton-second-wrapper" *ngIf="!contentLoaded && !mobileView">
            <div>
                <div class="skeleton-second-wrapper-title">
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '230px', 'margin-left': '5px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="skeleton-wrapper-title">
                        <div>
                            <ngx-skeleton-loader
                                [theme]="{width: '350px', 'border-radius': '10px', height: '35px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="skeleton-text-line">
                <ngx-skeleton-loader [theme]="{width: '70%'}"></ngx-skeleton-loader>
            </div>
            <div class="skeleton-text-line">
                <ngx-skeleton-loader count="3" [theme]="{width: '100%'}"></ngx-skeleton-loader>
            </div>
            <div class="skeleton-text-line">
                <ngx-skeleton-loader [theme]="{width: '60%'}"></ngx-skeleton-loader>
            </div>
            <div class="skeleton-text-line">
                <ngx-skeleton-loader count="2" [theme]="{width: '90%'}"></ngx-skeleton-loader>
            </div>
        </div>
        
        <!-- Skeleton-loader for mobile devices -->
        <div *ngIf="!contentLoaded && mobileView">
            <ngx-skeleton-loader
            [theme]="{width: '85%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader
            [theme]="{width: '75%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '70%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '90%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '10px', height: '80px', 'margin-bottom': '10px', 'margin-left': '0px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '75%', 'border-radius': '10px', height: '35px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '10px', height: '280px', 'margin-left': '0px' }">
            </ngx-skeleton-loader>
        </div>
        
        <div *ngIf="contentLoaded && !emptyTOC" class="toc-wrapper col-sm-12 col-md-3 col-lg-3 active-toc" [ngClass]="{'isBucketsDisplayed': isBucketExpandedMobile && mobileView , 'isSearchEnabled' : !isSearchDisabled}">
            <app-cps-table-of-contents *ngIf="doc.isActive" [currentDocument]="doc"></app-cps-table-of-contents>
        </div>
        
        <div *ngIf="contentLoaded" style="position: relative" [ngClass]="{'monograph-wrapper': !doc.id.endsWith('_g'), 'generic-wrapper': doc.id.endsWith('_g')}" class="col-sm-12 col-md-9 col-lg-9" #docWrapper>
            <app-cps-monograph-messages [currentDocument]="doc" *ngIf="!doc.id.endsWith('_g')"></app-cps-monograph-messages>
            
            <!-- Display the document HTML -->
            <div *ngIf="monographSections == null || monographSections.length == 0"
                [innerHTML]="htmlElement.innerHTML | safe: 'html'">
            </div>

            <!-- If the document is too big, split it into sections then display each virtually
                 Because we still need all the meta tags and whatnot we display the first part -->
	        <div *ngIf="displayHTML != null && monographSections != null && monographSections.length > 0"
                class="virtualscroll-enabled">
                <div [innerHTML]="displayHTML.innerHTML | safe: 'html'"></div>
            </div>
            

            <div *ngFor="let section of monographSections">
                <a name="{{section.sectionName}}" id="{{section.sectionName}}" ></a>
                <div (insideViewport)="onSectionView($event, section.offsetTop)" class="virtual-section-head" [attr.data]="section.sectionName">
                    <div *ngIf="section.isHidden == false || section.sectionIndex < 1 || isDisplayFullHTML">
                        <div [innerHTML]="section.element.innerHTML | safe: 'html'"></div>
                    </div>
                    <div *ngIf="section.isHidden == true">
                        <div class="section-placeholder">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>