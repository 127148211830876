import { subscriptionInfo } from "./subscriptionInfo";

export class userProfile {

    public userId: string;

    public userFirstName: string;

    public userLastName: string;

    public fname: string;

    public lname: string;

    public membertype: string;

    public login_type: string;

    public authorities: string[];

    public subscriberId: string;

    public billingid: string;

    public industry: string;

    public profession: string;

    public client_id: string;

    public access_token: string;

    public refresh_token: string;

    public active_Subscription: string;

    public subscriptionInfo: subscriptionInfo[];

    public province: string;

    public membercode: string;

    constructor() {
      //Empty
    }

}
