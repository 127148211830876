import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { DocumentListActionTypes, ReloadTableOfContents, ShowDocumentSuccess } from 'src/app/actions/document-list.actions';
import { DocumentService } from 'src/services/document.service';
import { AppConstants } from 'src/utils/app.constants';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CpsProductInfoModalComponent } from 'src/app/modals/cps-product-info-modal/cps-product-info-modal.component';
import { ofType } from '@ngrx/effects';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentItem } from '../document-models/document-item';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from 'src/services/session.service';
import { SearchService } from 'src/app/search/search.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { ViewportScroller } from '@angular/common';

export class TocItem {
  children: TocItem[] = [];
  tagname = '';
  selector = '';
  text = '';
  href = '';
  isCurrentlyViewing = false;
  element = null;
}

@Component({
  selector: 'app-cps-table-of-contents',
  templateUrl: './cps-table-of-contents.component.html',
  styleUrls: ['./cps-table-of-contents.component.scss'],
})
export class CpsTableOfContentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tocWrapper', {static: true}) tocWrapper: ElementRef;
  @Input() currentDocument: DocumentItem;

  @Output() ifpCountChanged: EventEmitter<number> = new EventEmitter();

  public sectionHeaderInfoList: Array<TocItem>;

  public tocTopLinkElements: Array<Element>;
  public productImageElement: Element;

  public tocOffsetTop: number;

  public tocModel: TocItem;
  public allTablesModel: Array<Element>;
  public allFiguresModel: Array<Element>;

  public isMobile: boolean;
  public isTocCollapsed: boolean;
  public isTocInitialized: boolean;
  public isAllTablesCollapsed: boolean;
  public isAllFiguresCollapsed: boolean;

  public ifpCount = 0;
  public isIFP = false;
  public isResultPage = false;

  private mobileTest = false;

  public hasMultiple = false;

  private subResizeEvent: Subscription;
  private subNavigationEvent: Subscription;

  protected isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private scrollToLastViewedSectionTimeout: any;
  private reinitializeTimeout: any;
  private afterInitTimeout: any;

  private config: ModalOptions = {
    keyboard: false,
    backdrop: 'static',
    class: 'modal-lg'
  };

  constructor(private renderer2: Renderer2, private documentService: DocumentService,
              private actionListener$: ActionsSubject, private modalService: BsModalService,
              private datalayerService: DatalayerService,
              private sessionService: SessionService,
              private router: Router,
              private searchService: SearchService,
              private viewportScroller: ViewportScroller) {
    this.sectionHeaderInfoList = new Array<TocItem>();
    this.tocTopLinkElements = new Array<Element>();
    this.productImageElement = undefined;

    this.isMobile = window.innerWidth < AppConstants.MOBILE_SIZE;
    this.isTocCollapsed = false;
    this.isTocInitialized = false;
    this.isAllTablesCollapsed = true;
    this.isAllFiguresCollapsed = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const activeToc = this;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobileTest = true;
    }

    this.subNavigationEvent = router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/search/')) {
        this.isResultPage = true;
      }
    });

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.isMobile = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.isMobile;
    });

    this.actionListener$.pipe(ofType(DocumentListActionTypes.ShowDocumentSuccess), takeUntil(this.destroy$))
      .subscribe((action: ShowDocumentSuccess) => {
        // in the case the toc isn't initialized and the document hasn't been set by the @input
        if (activeToc.currentDocument === undefined) {
          activeToc.currentDocument = activeToc.documentService.getCurrentlyViewedDocument();
        }

        if (activeToc.currentDocument === null) {
          activeToc.currentDocument = action.document;
        }

        if (!!activeToc.currentDocument && activeToc.currentDocument.isActive
            && action.document === activeToc.currentDocument) {
          this.scrollToLastViewedSectionTimeout = setTimeout(() => {
            activeToc.initialize();

            window.scrollTo(window.scrollX, window.scrollY - 1);
            window.scrollTo(window.scrollX, window.scrollY + 1);
          });
        }
      });

    this.actionListener$.pipe(ofType(DocumentListActionTypes.ReloadTableOfContents), takeUntil(this.destroy$))
      .subscribe((action: ReloadTableOfContents) => {
        this.reinitializeTimeout = setTimeout(() => {
          activeToc.initialize();
        });
      });

    this.subResizeEvent = fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(() => {
      this.onResize();
    });
  }

  ngOnInit(): void {
    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      });

  }

  ngAfterViewInit(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/search/')) {
        this.isResultPage = true;
      }
    });

    // DIG-3565 This block is just for the search result pages
    // NORMALLY for documents initiialiation is done via
    // ReloadTableOfContents listener here, and ShowDocumentSuccess in document.service
    if (this.router.url.indexOf('/search/') != -1) {
      // using setTimeout prevents the expression changed after evaluation error caused
      // by the template evaluating content that is loaded after it is initialized
      this.afterInitTimeout = setTimeout(() => {
        this.initialize();
      });
    }
  }

  initialize(): void {

    const currentDoc = document.querySelector('.active-document');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.sectionHeaderInfoList.forEach(section => {
          const sectionName = section.href.replace('#', '').toLowerCase();
          let data = entry.target.getAttribute('data');

          if (data) {
            data = data.toLowerCase();
          }

          if (sectionName === entry.target.id.toLowerCase() || sectionName === data) {

            if (entry.intersectionRect.y > 0) {
              section.isCurrentlyViewing = true;
            } else {
              section.isCurrentlyViewing = false;
            }
          }
        });
      });
    }, { rootMargin: '-34%' });//was -20% but was selecting the item above it at all times. We just want the item selected to be highlighted


    if (this.currentDocument === null || this.currentDocument === undefined) {
      const rawResultPageTocUl = document.querySelectorAll('.bucketHeaderTOC');
      if (rawResultPageTocUl) {
        this.isResultPage = true;
        this.sectionHeaderInfoList = new Array<TocItem>();
        this.tocModel = this.populateResultPageTocModel(rawResultPageTocUl);

        // Track all sections that have a bucketHeaderTOC class
        document.querySelectorAll('.bucketHeaderTOC').forEach((section) => {
          observer.observe(section);
        });
      }
    } else {
        this.isResultPage = false;
        const rawTocUl = currentDoc.querySelector('.navigator-root > li > ul');
        const ifpCount = currentDoc.querySelectorAll('.infoForPatientBody');

        const tcSubtopics = currentDoc.querySelectorAll('.navigator-root > li > ul.dita-nav-subtopics-tc > li');

        this.ifpCountChanged.emit(ifpCount.length);

        if (rawTocUl) {
          this.sectionHeaderInfoList = new Array<TocItem>();

          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < tcSubtopics.length; i++) {
            if (tcSubtopics[i]) {
              const tables = tcSubtopics[i].getElementsByClassName('navigator-table');

              let j = 0;
              while (tables.length > j) {

                if (tables[0].tagName.toLowerCase() === 'span') {
                  tables[0].parentNode.removeChild(tables[0]);
                } else {
                  j++;
                }
              }

              const figures = tcSubtopics[i].getElementsByClassName('navigator-figure');

              j = 0;
              while (figures.length > j) {
                if (figures[0].tagName.toLowerCase() === 'span') {
                  figures[0].parentNode.removeChild(figures[0]);
                } else {
                  j++;
                }
              }

              // this block is designed to remove excessive subsections in the TOC. After this we should only go one subsection level deep
              if (tcSubtopics[i].childNodes.length > 1) {
                // tslint:disable-next-line: prefer-for-of
                for (let a = 1; a < tcSubtopics[i].childNodes.length; a++) {
                  // tslint:disable-next-line: prefer-for-of
                  for (let b = 0; b < tcSubtopics[i].childNodes[a].childNodes.length; b++) {
                    if (tcSubtopics[i].childNodes[a].childNodes[b].childNodes.length > 1) {
                      tcSubtopics[i].childNodes[a].childNodes[b].childNodes[1].remove();
                    }
                  }
                }
              }

              rawTocUl.appendChild(tcSubtopics[i]);
            }
          }

          this.tocModel = this.populateTocModel(rawTocUl, currentDoc);

          const toplink = currentDoc.querySelectorAll('.document_top_links > .document_top_link > a');

          if (!this.hasMultiple && toplink.length === 0) {
            const tocContainer = document.querySelector('.toc-container').parentElement.parentElement;
            tocContainer.setAttribute('class', 'toc-wrapper');
            tocContainer.setAttribute('style', 'display: none;');

            if (!!document.querySelector('.content')) {
              document.querySelector('.content').parentElement.parentElement.setAttribute('class', 'doc-content-wrapper');
            }

            if (!!document.querySelector('.doc-content-wrapper') && !!document.querySelector('.doc-content-wrapper').children[1]) {
              document.querySelector('.doc-content-wrapper').children[1].setAttribute('style', 'width:100%; max-width: 100%');
            }

          }

        } else {
            if (this.currentDocument.tocModel !== undefined) {
              this.tocModel = this.currentDocument.tocModel;
              this.sectionHeaderInfoList = this.currentDocument.sectionHeaderInfoList;
            }
        }

        // Track all sections that have a collapsablesection class
        document.querySelectorAll('.collapsablesection').forEach((section) => {
          observer.observe(section);
        });

        // Track all sections that have a section-head class
        document.querySelectorAll('.section-head').forEach((section) => {
          observer.observe(section);
        });

        // Track all sections that have a content-inset class
        document.querySelectorAll('.content-inset').forEach((section) => {
          observer.observe(section);
        });

      document.querySelectorAll('.virtual-section-head').forEach((section) => {
        observer.observe(section);
      });

        this.getTopLinksSection();
    }
    this.isTocCollapsed = this.isMobile;
  }

  populateResultPageTocModel(currentHtmlNode: NodeListOf<Element>): TocItem {
    const currentEl = new TocItem();
    currentEl.tagname = 'ul';
    this.sectionHeaderInfoList.push(currentEl);
    currentHtmlNode.forEach(element => {
      const currentElementLI = new TocItem();
      currentElementLI.tagname = 'li';
      const currentElement = new TocItem();
      currentElement.tagname = 'a';
      currentElement.text = element.children[0].children[0].innerHTML;
      currentElement.href = '#' + element.id;
      const mainSectionHeading: HTMLElement = document.getElementById(element.id);
      if (mainSectionHeading != null) {
        currentElement.element = mainSectionHeading;
      }
      this.sectionHeaderInfoList.push(currentElement);
      currentElementLI.children.push(currentElement);
      currentEl.children.push(currentElementLI);
    });
    return currentEl;
  }

  // recursive function to traverse the original TOC ul
  populateTocModel(currentHtmlNode: Element, documentBody: Element): TocItem {

    const currentEl = new TocItem();
    currentEl.tagname = currentHtmlNode.localName;

    // populate data if this tag has stuff
    if (currentHtmlNode.localName === 'a' || currentHtmlNode.localName === 'span') {
      currentEl.text = currentHtmlNode.innerHTML;
      currentEl.href = currentHtmlNode.getAttribute('href');

      // Sometimes the href is missing and instead the reference lives in a title attribute
      if (currentHtmlNode.getAttribute('href') == null) {
        currentEl.href = currentHtmlNode.getAttribute('title');
      }

      // also add this node to a list of topics for scrolling
      this.sectionHeaderInfoList.push(currentEl);
    }

    if (currentHtmlNode.hasChildNodes()) {
      const children = currentHtmlNode.children;

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const childModel = this.populateTocModel(child, documentBody);
        currentEl.children.push(childModel);
      }
    } else {
      // this is the last node
      return currentEl;
    }

    if (currentEl.children.length > 1) {
      this.hasMultiple = true;
    }

    // return the model so previous level can append it
    return currentEl;
  }

  populateTablesModel(activeDocumentBody: Element, query: string): Array<Element> {
    const tablesModel = new Array<Element>();

    const rootUl = activeDocumentBody.querySelector(query);
    if (rootUl !== null) {
      rootUl.childNodes.forEach((li: Element) => {
        tablesModel.push(li);
      });
      return tablesModel;
    }
    return null;
  }

  getTopLinksSection(): void {
    const currentDoc = document.querySelector('.active-document');
    if (currentDoc) {
      this.tocTopLinkElements = new Array<Element>();
      if (this.currentDocument.type === AppConstants.MONOGRAPH_TYPE) {
        const topLinks = currentDoc.querySelectorAll('.document_top_links > .document_top_link > a');
        topLinks.forEach(link => {
          if (link.innerHTML.toLowerCase() === 'product image' || link.innerHTML.toLowerCase() === 'image du produit') {
            this.productImageElement = link;
          } else {
            this.tocTopLinkElements.push(link);
          }
        });
      } else {
        this.allTablesModel = this.populateTablesModel(currentDoc, '.dita-nav-tables');
        this.allFiguresModel = this.populateTablesModel(currentDoc, '.dita-nav-figures');
      }
    }
  }

  logClickEventToGTM(tocItem: TocItem): void {
    const currentDocHtml = document.querySelector('.active-document');
    this.datalayerService.tableOfContentsClickEvent(tocItem, this.currentDocument, currentDocHtml);
  }

  scrollTo(tocItem: TocItem, currElement: Element): void {
    const activeDocument = document.querySelector('.active-document');

    let tocbodywrapper : HTMLElement = document.querySelector('.toc-wrapper');
    const isTocItemHaveChildren = tocItem.children.length > 0 && currElement.parentElement.classList.contains('collapsible');
    const isDocumentUsingVirtualScroll = !!activeDocument.querySelector('.virtualscroll-enabled');

    if (this.isResultPage) {
      let mainSectionId = tocItem.href;
      if (mainSectionId != null) {
        mainSectionId = mainSectionId.replace('#', '');
      }
      let mainSectionHeading: HTMLElement = document.querySelector('#' + mainSectionId);
      if (mainSectionHeading == null) {
        mainSectionHeading = document.getElementById(mainSectionId);
      }
      tocItem.element = mainSectionHeading;

      const headerOffset = this.mobileTest ? 150 : 210;
      const elementPosition = document.querySelector(tocItem.href).getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      this.sessionService.isScrollLocked = false;

      window.scrollTo({
        top: offsetPosition
      });

    } else if (this.currentDocument && this.currentDocument.isActive) {

      const currentDoc = document.querySelector('.active-document');
      let mainSectionId = tocItem.href;
      if (mainSectionId != null) {
        mainSectionId = mainSectionId.replace('#', '');
      }
      let mainSectionHeading: HTMLElement = currentDoc.querySelector('#' + mainSectionId);
      if (mainSectionHeading == null) {
        mainSectionHeading = currentDoc.querySelector('a[name="' + mainSectionId + '"');
      }
      tocItem.element = mainSectionHeading;
      let headerOffset = 0;

      if(navigator.userAgent.includes('iPhone')) {
        headerOffset = (this.mobileTest || this.isMobile) ? tocbodywrapper.getBoundingClientRect().height + 95 : 100;
      } else {
        headerOffset = (this.mobileTest || this.isMobile) ? 150 : 100;
      }

      const elementPosition = mainSectionHeading.getBoundingClientRect().top;

      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      const currentUrl = window.location.href;
      if (tocItem.element.classList.contains('content-inset') || tocItem.element.classList.contains('algorithms') || tocItem.element.classList.contains('dita-fig') || currentUrl.includes("/clininfo")) {
        offsetPosition += -125;
      }

      if (isDocumentUsingVirtualScroll) {
        this.documentService.scrollToActiveDocumentSection(mainSectionId);
      } else {
        window.scrollTo({
          top: offsetPosition
        });
      }

      this.logClickEventToGTM(tocItem);
      this.initialize();  // Re-evaluate the state of TOC to avoid misleading highlights, item 4 in DIG-3159
    }

    // in mobile we want to close the toc only if there's no more subsections to open
    if (this.isMobile && !isTocItemHaveChildren) {
      this.isTocCollapsed = true;
    }
  }

  scrollToForShortMenu(tocItem: TocItem, currElement: Element): void {
    // const activeDocument = document.querySelector('.active-document');
    const isTocItemHaveChildren = tocItem.children.length > 0 && currElement.parentElement.classList.contains('collapsible');

    if (this.isResultPage) {
      let mainSectionId = tocItem.href;
      if (mainSectionId != null) {
        mainSectionId = mainSectionId.replace('#', '');
      }
      let mainSectionHeading: HTMLElement = document.querySelector('#' + mainSectionId);
      if (mainSectionHeading == null) {
        mainSectionHeading = document.getElementById(mainSectionId);
      }
      tocItem.element = mainSectionHeading;

      const headerOffset = this.mobileTest ? 150 : 125;
      const elementPosition = document.querySelector(tocItem.href).getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      this.sessionService.isScrollLocked = false;

      window.scrollTo({
        top: offsetPosition
      });

    } else if (this.currentDocument && this.currentDocument.isActive) {

      const currentDoc = document.querySelector('.active-document');
      let mainSectionId = tocItem.href;
      if (mainSectionId != null) {
        mainSectionId = mainSectionId.replace('#', '');
      }
      let mainSectionHeading: HTMLElement = currentDoc.querySelector('#' + mainSectionId);
      if (mainSectionHeading == null) {
        mainSectionHeading = currentDoc.querySelector('a[name="' + mainSectionId + '"');
      }
      tocItem.element = mainSectionHeading;

      const headerOffset = (this.mobileTest || this.isMobile) ? 550 : 125;
      const elementPosition = mainSectionHeading.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;


      this.documentService.scrollToActiveDocumentSection(mainSectionId);
      window.scrollTo({
        top: offsetPosition
      });

      this.logClickEventToGTM(tocItem);
      this.initialize(); // Re-evaluate the state of TOC to avoid misleading highlights, item 4 in DIG-3159
    }

    // in mobile we want to close the toc only if there's no more subsections to open
    if (this.isMobile && !isTocItemHaveChildren) {
      this.isTocCollapsed = true;
    }
  }


  scrollToTable(tableLi: Element): void {
    const currentDoc = document.querySelector('.active-document');
    const elementToScrollTo = currentDoc.querySelector(tableLi.children.item(0).getAttribute('title'));
    let offset = 200;

    if (window.innerWidth < AppConstants.MOBILE_SIZE) {
      this.isTocCollapsed = true;
      offset = 235;
    }
    this.viewportScroller.setOffset([0, offset]);
    this.viewportScroller.scrollToAnchor(elementToScrollTo.getAttribute("id"));
  }

  toggleCollapsibleSection(event: Event): void {

    event.stopPropagation();
    const currentEl = (event.target as Element);

    if (currentEl.parentElement.classList.contains('collapsible')) {
      const expandedEls = document.querySelectorAll('.expanded');
      expandedEls.forEach(el => {
        if (!el.contains(currentEl)) {
          el.classList.remove('expanded');
          el.classList.add('collapsed');
        }
      });

      if (currentEl.classList.contains('collapsed')) {
        currentEl.classList.remove('collapsed');
        currentEl.classList.add('expanded');
      } else {
        currentEl.classList.remove('expanded');
        currentEl.classList.add('collapsed');
      }
    }
  }

  openProductImageModal(): void {

    CpsProductInfoModalComponent.monographHTML = this.documentService.getDocumentHtml(this.currentDocument);

    this.modalService.show(CpsProductInfoModalComponent, this.config);
  }

  toggleTocCollapsed(): void {

    this.isTocCollapsed = !this.isTocCollapsed;

  }

  onResize(): void {

    this.isMobile = window.innerWidth < AppConstants.MOBILE_SIZE;
    this.isTocCollapsed = this.isMobile;

    const container = document.getElementById('toc-container');
    if (!!container) {
      if (window.innerWidth < AppConstants.MOBILE_SIZE) {
        const e = fromEvent(document, 'click').pipe(takeUntil(this.destroy$)).subscribe(event => {
          if (container !== <HTMLInputElement> event.target && !container.contains(<HTMLInputElement> event.target)) {
            this.isTocCollapsed = true;
            e.unsubscribe();
          }
        });
      } else {
        const e = fromEvent(document, 'click').pipe(takeUntil(this.destroy$)).subscribe(event => {
          if (container !== <HTMLInputElement> event.target && !container.contains(<HTMLInputElement> event.target)) {
            this.isTocCollapsed = false;
            e.unsubscribe();
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subResizeEvent) { this.subResizeEvent.unsubscribe(); }
    if (this.subNavigationEvent) { this.subNavigationEvent.unsubscribe(); }
    this.destroy$.next(true);
    this.destroy$.complete();
    clearTimeout(this.scrollToLastViewedSectionTimeout);
    clearTimeout(this.reinitializeTimeout);
    clearTimeout(this.afterInitTimeout);
  }

}
