<div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
    <app-cps-tool-bar [isSearchDisabled]="false" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>
    <div class="doc-content-wrapper content patient-info-content">
        
        <!-- Skeleton-loader for desktop view -->
        <div class="skeleton-wrapper" *ngIf="!contentLoaded && !mobileView">
            <div>
                <ngx-skeleton-loader [theme]="{width: '350px', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <div>
                    <ngx-skeleton-loader [theme]="{width: '250px', 'margin-left': '5px', display: 'block', 'border-radius': '10px'}"></ngx-skeleton-loader>               
                    <ngx-skeleton-loader [theme]="{width: '200px', 'margin-left': '5px', display: 'block', 'border-radius': '10px'}"></ngx-skeleton-loader>               
                </div>
                <ngx-skeleton-loader [theme]="{width: '300px', 'margin-left': '900px', 'border-radius': '10px'}"></ngx-skeleton-loader>
        
                <div>
                    <ngx-skeleton-loader
                        [theme]="{width: '100%', 'border-radius': '10px', height: '70px', 'margin-left': '5px', 'margin-bottom': '20px'}"></ngx-skeleton-loader>
                </div>
        
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                </div>
                <div class="skeleton-text-line">
                    <ngx-skeleton-loader [theme]="{width: '95%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                </div>
        
                <div class="skeleton-center-position">
                    <ngx-skeleton-loader appearance="circle"
                        [theme]="{ width: '100%', 'border-radius': '10px', height: '500px', 'margin-bottom': '20px'}">
                    </ngx-skeleton-loader>                
                </div>

                <ngx-skeleton-loader [theme]="{width: '350px', height: '35px', 'border-radius': '10px', 'margin-left': '5px'}"></ngx-skeleton-loader>
                <div>
                    <ngx-skeleton-loader [theme]="{width: '100%', 'margin-left': '5px', display: 'block', 'border-radius': '10px', 'margin-left': '5px'}"
                        count="4"></ngx-skeleton-loader>
                </div>
            </div>
        </div>      
        <!-- Skeleton-loader for mobile devices -->
        <div *ngIf="!contentLoaded && mobileView">
            <ngx-skeleton-loader [theme]="{width: '85%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="3" [theme]="{width: '60%', display: 'block', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '85%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="3" [theme]="{width: '60%', display: 'block', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '85%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="3" [theme]="{width: '60%', display: 'block', 'border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '10px', height: '280px', 'margin-left': '0%'}"></ngx-skeleton-loader>
        </div>

        <div *ngIf="showTOC && contentLoaded" class="toc-wrapper col-sm-12 col-md-3 col-lg-3 active-toc" [ngClass]="{'isBucketsDisplayed': isBucketExpandedMobile && mobileView , 'isSearchEnabled' : !isSearchDisabled}">
            <app-cps-table-of-contents *ngIf="doc.isActive" [currentDocument]="doc" [count]=Counter></app-cps-table-of-contents>
        </div>
        <div *ngIf="contentLoaded" #docWrapper class="patientinfo-wrapper" [ngClass]="showTOC ? 'col-sm-12 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12 noTOC' ">     
            <div [innerHTML]="htmlElement.innerHTML | safe: 'html'"></div>
        </div>
    </div>
</div>