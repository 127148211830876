import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { DatalayerService } from 'src/services/datalayer.service';
import { AppConstants } from 'src/utils/app.constants';
import { SearchService } from '../search.service';
import { Result } from '../SearchResult.interface';
import { ResultGroup } from './cps-results-group.interface';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { DoMoreSearch, DoLessSearch } from 'src/app/actions/search-result.actions';
import { HeaderService } from 'src/services/header.service';

@Component({
  selector: 'app-cps-results-group',
  templateUrl: './cps-results-group.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./cps-results-group.component.scss']
})
export class CpsResultsGroupComponent implements OnInit {

  @Input() data: ResultGroup;

  toggleFlag = false;
  isNpd = false;

  limit = 5;
  start = 0;
  end = 1;
  searchTerm: string;
  counter = 1;
  showMoreFlag: boolean;
  maxResults: number;
  categoryCount: number;

  constructor(public translationService: TranslateService,
              public searchService: SearchService,
              private activatedRoute: ActivatedRoute,
              private datalayerService: DatalayerService,
              private headerService: HeaderService,
              private store: Store<State>) {}

    ngOnInit(): void {
    this.searchService.getSearchTermObservable().subscribe(searchTermState => {
      this.searchTerm = searchTermState.searchTerm;
    });
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thizz = this;
    this.store.select(state => state.searchResultKey)
      .subscribe(action => {
        if (!!action) {
          const maxResults = action.groupMaxResultCounts[thizz.data.id];
          this.toggleFlag = this.searchService.getSearchResultGroupState(this.data.id);
          if (!!maxResults) {
            thizz.maxResults = maxResults;
          }
          this.isNpd = action.npdCount > 0;
          // DIG 3288 When toggling language, maxresults doesn't load, adding these so that show more button can use the count of each bracket as an ngIf condition 
          if (window.location.pathname.includes('/monographs')) {
            const categoryCount = action.monographCount;
            thizz.categoryCount = categoryCount;
          }
          if (window.location.pathname.includes('/conditions')) {
            const categoryCount = action.conditionsCount;
            thizz.categoryCount = categoryCount;
          }
          if (window.location.pathname.includes('/patientinfo')) {
            const categoryCount = action.patientinfoCount;
            thizz.categoryCount = categoryCount;
          }

          if (!!action.subcounts && action.subcounts.size > 0) {

            if (thizz.data.type === 'MONOGRAPH') {
              thizz.maxResults =  action.subcounts.get(thizz.data.qualifier);
            } else {
              thizz.maxResults =  action.subcounts.get(thizz.data.type);
            }
          }
        }
      });
  }

  showMoreResults(searchType: string, qualifier: string, nextIndex: number): void {
    this.end = nextIndex;
    this.searchService.scrollPositions.set(this.activatedRoute.snapshot.params.switch, window.scrollY);
    this.store.dispatch(new DoMoreSearch(this.searchTerm, nextIndex, this.limit, searchType, qualifier, this.data.id));
  }

  showLessResults(searchType: string, qualifier: string): void {
    this.store.dispatch(new DoLessSearch(this.searchTerm, this.start , this.start + 5, searchType, qualifier));
  }

  onSelect(result: Result): void {

    this.headerService.setSearchBarCondensed(false);

    this.searchService.scrollPositions.set(this.activatedRoute.snapshot.params.switch, window.scrollY);

    let linkUrl = 'document/' + DocumentItem.getPathFromType(result.type) + '/' + result.id;
    if (result.type === AppConstants.MA_PI_TYPE){
      linkUrl = 'document/' + DocumentItem.getPathFromType(result.type) + '/' + result.parentId + '/' + result.id;
    }
    const searchCategory = this.activatedRoute.snapshot.params.switch;
    this.datalayerService.searchResultsClickEvent(linkUrl, searchCategory);
    this.searchService.navigateTo(result);
  }

  toggleBucket(): void {
    this.toggleFlag = !this.toggleFlag;
    this.searchService.updateSearchResultGroupState(this.data.id, this.toggleFlag);
  }

  manufacturerListFilter(documentChunkText: string): string[] {
    const lines = documentChunkText.split('\n')
      .map(line => line.trim())
      .filter(line => line.includes('DIN(s)') && line !== '');
    return lines;
  }
}
