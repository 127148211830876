import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/services/home.service';
import { WhatsNew } from './whatsnew.interface';
import { WhatsNewTC } from './whatsnew-tc.interface';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatalayerService } from 'src/services/datalayer.service';

@Component({
  selector: 'app-cps-home-sidebar',
  templateUrl: './cps-home-sidebar.component.html',
  styleUrls: ['./cps-home-sidebar.component.scss']
})
export class CpsHomeSidebarComponent implements OnInit, OnDestroy {

  private whatsNew: WhatsNew[];

  public today: number = Date.now();

  public whatsNewTC: WhatsNewTC[];

  public categories = [];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(public translationService: TranslateService, public homeService: HomeService, private datalayerService: DatalayerService) {

  }

  filterBy(catg: string): Array<WhatsNew> {
    return this.whatsNew.filter(item => item.category === catg);
  }

  ngOnInit(): void {

    this.homeService.getWhatsNew().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const catgMap = new Map<string, string>();
      this.whatsNew = value.whatsNew;

      this.whatsNew.forEach(item => {
        if (item.filename != null){
	    item.id = item.filename.substring(0, item.filename.length - 7);
        } else {
	    console.log("filename for " + item.brandName + " is missing.");
	}
        catgMap.set(item.category, null);
      });
      this.categories = Array.from(catgMap.keys());
      this.categories.sort();
      this.categories.reverse();
    });

    this.homeService.getWhatsNewTC().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.whatsNewTC = value.whatsNewTC;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  homePageClickEvent(subcategory: string): void {
    this.datalayerService.homePageClickEvent('whatsnew', subcategory);
  }
}
