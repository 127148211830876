<div id={{doc.id}}>
    <div [ngClass]="{ 'hidden': !doc.isActive, 'active-document': doc.isActive }" *ngIf="doc.isActive">
        <app-cps-tool-bar [isSearchDisabled]="false" [currentDocument]="doc" [documentHtml]="htmlElement.innerHTML"></app-cps-tool-bar>
        
        <div class="doc-content-wrapper">

            <!-- Skeleton-loader for desktop view -->
            <div class="skeleton-first-wrapper" *ngIf="!contentLoaded && !mobileView">
                <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '230px', height: '400px', 'border-radius': '10px' }">
                    </ngx-skeleton-loader>
                </div>
                <div class="skeleton-first-wrapper-items">
                    <ngx-skeleton-loader
                            [theme]="{width: '350px', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '300px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    </div>
                    <ngx-skeleton-loader count="2" [theme]="{width: '300px', 'margin-left': '660px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    
                    <div>
                        <ngx-skeleton-loader [theme]="{width: '350px', 'border-radius': '10px', height: '35px', 'margin-left': '5px', 'margin-bottom': '20px'}"></ngx-skeleton-loader>
                    </div>
                    
                    <div class="skeleton-text-line">
                    <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="skeleton-text-line">
                        <ngx-skeleton-loader [theme]="{width: '95%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="skeleton-text-line">
                        <ngx-skeleton-loader [theme]="{width: '100%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="skeleton-text-line">
                        <ngx-skeleton-loader [theme]="{width: '90%', 'border-radius': '10px', 'margin-bottom': '20px'}"></ngx-skeleton-loader>
                    </div>

                    <div>
                        <ngx-skeleton-loader
                            [theme]="{width: '350px', 'border-radius': '10px', height: '35px', 'margin-left': '5px'}"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            
            <div class="skeleton-second-wrapper" *ngIf="!contentLoaded && !mobileView">
                <div>
                    <ngx-skeleton-loader [theme]="{width: '230px', 'margin-left': '5px', display: 'block', 'border-radius': '10px'}" count="2"></ngx-skeleton-loader>
                </div>
                
                <div>
                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '950px', 'border-radius': '10px', height: '280px', 'margin-left': '55px' }">
                    </ngx-skeleton-loader>
                </div>
            </div>
            
            <!-- Skeleton-loader for mobile devices -->
            <div *ngIf="!contentLoaded && mobileView">
                <ngx-skeleton-loader [theme]="{width: '100%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '55%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" [theme]="{width: '80%', 'margin-left': '0px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" [theme]="{width: '50%', 'margin-left': '50%', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{width: '60%', height: '35px', 'border-radius': '10px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '100%', 'border-radius': '10px', height: '280px', 'margin-left': '0%'}"></ngx-skeleton-loader>
            </div>

            <div class="toc-wrapper col-sm-12 col-md-3 col-lg-3 active-toc" *ngIf="contentLoaded" [ngClass]="{'isBucketsDisplayed': isBucketExpandedMobile && mobileView , 'isSearchEnabled' : !isSearchDisabled}">
                <app-cps-table-of-contents *ngIf="doc.isActive" [currentDocument]="doc"></app-cps-table-of-contents>
            </div>

            <div #docWrapper class="condition-wrapper col-sm-12 col-md-9 col-lg-9" *ngIf="contentLoaded">
                <div [innerHTML]="htmlElement.innerHTML | safe: 'html'">
                </div>
            </div>
        </div>
    </div>
</div>