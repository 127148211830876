<div class="globalNav-container" (blur)="leaveNavBar();">
    <a (click)="goToHomePage()">
    <div class="cpsLogoDiv d-xxl-inline-block d-xl-inline-block d-lg-inline-block d-md-inline-block d-sm-inline-block d-none ">
        <span class="cpsLogo site-logo-header">
        </span>
    </div>
    </a>
    <div class="globalNav-menu">
        <ul class="nav nav-pills">
            <li ngbDropdown #drugsConditionsDropdown="ngbDropdown" autoClose="true"
            (mouseenter)="hoverOnDropdown('drugs');" (mouseleave)="leaveNavBar();">
                <ng-container *ngIf="isFullAccess">
                    <a class="globalNav-menu-item" ngbDropdownAnchor tabindex="0"
                        (focus)="drugsConditionsDropdown.open(); resourcesDropdown.close(); toolDropdown.close();">
                        {{ 'global-nav.drugsConditions' | translate }} <span class="down-arrow-cyan-icon globalNav-menu-arrow"></span>
                    </a>
                    <ul class="globalNav-menuList" ngbDropdownMenu (mouseleave)="drugsConditionsDropdown.close()">
                        <li class="globaNavSubMenu" ngbDropdownItem *ngFor="let item of drugsMenu"
                            (click)="onSubcategoryClicked('drugsConditions', item.link)">
                            <a [routerLink]="[ item.link | routerPipe ]" class="globalNav-menu-subitem" tabindex="0">{{ item.label | translate }}</a>
                        </li>
                    </ul>
                </ng-container>
                <ng-template [ngIf]="!isFullAccess">
                    <a class="globalNav-menu-item" ngbDropdownToggle tabindex="0"
                        (focus)="drugsConditionsDropdown.open(); resourcesDropdown.close(); toolDropdown.close();">
                        {{ 'global-nav.drugsCPS' | translate }} <span class="down-arrow-cyan-icon globalNav-menu-arrow"></span>
                    </a>
                    <ul class="globalNav-menuList" ngbDropdownMenu (mouseleave)="drugsConditionsDropdown.close()">
                        <li ngbDropdownItem *ngFor="let item of drugsMenuLimited" (click)="onSubcategoryClicked('drugs', item.link)" class="globaNavSubMenu">
                            <a [routerLink]="[ item.link | routerPipe ]" class="globalNav-menu-subitem">{{ item.label | translate }}</a>
                        </li>
                    </ul>
                </ng-template>
            </li>
            <li ngbDropdown #toolDropdown="ngbDropdown" autoClose="false"
                (mouseenter)="hoverOnDropdown('tool');" (mouseleave)="leaveNavBar()">
                <a class="globalNav-menu-item" ngbDropdownToggle tabindex="0"
                    (focus)="drugsConditionsDropdown.close(); resourcesDropdown.close(); toolDropdown.open();"
                    (click)="toolDropdown.open()">
                    {{ 'global-nav.tool' | translate }} <span class="down-arrow-cyan-icon globalNav-menu-arrow"></span>
                </a>
                <ul class="globalNav-menuList" ngbDropdownMenu (mouseleave)="toolDropdown.close()">
                    <li class="globaNavSubMenu" ngbDropdownItem *ngFor="let item of toolMenu"
                        (click)="onSubcategoryClicked('tool', item.link)">
                        <a [routerLink]="[ item.link | routerPipe ]" class="globalNav-menu-subitem">{{ item.label | translate }}</a>
                    </li>
                </ul>
            </li>
            <li ngbDropdown #resourcesDropdown="ngbDropdown" autoClose="false"
                (mouseenter)="hoverOnDropdown('resources');" (mouseleave)="leaveNavBar()">
                <a class="globalNav-menu-item" ngbDropdownToggle tabindex="0"
                    (focus)="drugsConditionsDropdown.close(); resourcesDropdown.open(); toolDropdown.close();"
                    (click)="resourcesDropdown.open()">
                    {{ 'global-nav.resources' | translate }} <span class="down-arrow-cyan-icon globalNav-menu-arrow"></span>
                </a>
                <ul class="globalNav-menuList" ngbDropdownMenu (mouseleave)="resourcesDropdown.close()">
                    <li class="globaNavSubMenu" ngbDropdownItem *ngFor="let item of resourceMenu"
                        (click)="onSubcategoryClicked('resources', item.link)">
                        <a *ngIf="isExternalLink(item.link); else internalResourceLink" target="_blank" [href]="[ item.link | routerPipe ]" class="globalNav-menu-subitem">
                            {{ item.label | translate }}
                            <i class="new-window-icon-black "></i>
                        </a>
                        <ng-template #internalResourceLink>
                            <a [routerLink]="[ item.link | routerPipe ]" class="globalNav-menu-subitem">{{ item.label | translate }}</a>
                        </ng-template>
                    </li>
                </ul>
            </li>
            <li>
                <a [routerLink]="[ 'lexi' | routerPipe ]" class="globalNav-menu-item lexi-menu-item" (click)="onLexiClicked()">
                    {{ 'global-nav.lexi' | translate }}
                    <span class="globalNav-menu-square-arrow"></span>
                </a>
            </li>

        </ul>
    </div>
</div>
