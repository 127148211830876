import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../search.service';
import { Autocomplete } from '../Autocomplete.interface';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cps-search-suggestions',
  templateUrl: './cps-search-suggestions.component.html',
  styleUrls: ['./cps-search-suggestions.component.scss']
})
export class CpsSearchSuggestionsComponent implements OnInit, OnDestroy {

  suggestionsArray: Autocomplete[] = [];
  documentsArray: Autocomplete[] = [];
  showSuggestions = false;
  searchTerm: string;
  seperator = " ";
  seperatorIndex:number = 0;
  
  private autocompleteSubscription: Subscription;
  private searchTermSubscription: Subscription;

  constructor(private searchService: SearchService,
    public translationService: TranslateService) { }
    
  ngOnDestroy(): void {
    this.autocompleteSubscription.unsubscribe();
    this.searchTermSubscription.unsubscribe();    
  }

  ngOnInit(): void {
    this.searchTermSubscription = this.searchService.getSearchTermObservable()
    .pipe(debounceTime(50))
    .subscribe(searchTermState => {
      this.refreshSuggestion(searchTermState.searchTerm);
      this.searchTerm = searchTermState.searchTerm;
    });
  }

  moveFocus(indexResult: number, e: KeyboardEvent): void {
    if (e.key === "ArrowDown" && indexResult < this.suggestionsArray.length + this.documentsArray.length) {
      (document.getElementsByClassName('searchItems')[indexResult + 1] as HTMLElement).focus();
      e.preventDefault();
    } else if (e.key === "ArrowUp" && indexResult !== 0) {
      (document.getElementsByClassName('searchItems')[indexResult - 1] as HTMLElement).focus();
      e.preventDefault();
    }
    e.stopPropagation();
  }

  selectedSuggestion(selectedString: string, searchType: string): void {
    this.seperatorIndex = this.searchTerm.lastIndexOf(this.seperator);
    if (this.seperatorIndex > 0) {
      selectedString = this.searchTerm.substring(0, this.seperatorIndex) + " " + selectedString;
    }
    this.searchService.setSearchTerm(selectedString.replace(/(<([^>]+)>)/gi, ""));
    this.searchService.getResultsWrapper(selectedString.replace(/(<([^>]+)>)/gi, ""), searchType);
    this.searchService.setBucketStatus(true);
  }

  selectedDocument(selectedDocument: Autocomplete, searchType: string): void {
    this.searchService.setSearchTerm(selectedDocument.item.replace(/(<([^>]+)>)/gi, ""));
    this.searchService.setBucketStatus(true);
    this.searchService.navigateToDocument(selectedDocument, searchType);
  }

  refreshSuggestion(searchString: string): void {
    if (!!searchString) {
      this.autocompleteSubscription = this.searchService.getAutocompleteSuggestions(searchString).subscribe((autoCompleteResponse) => {
        if (!!autoCompleteResponse) {
          this.suggestionsArray = autoCompleteResponse.filter(data => !!data.item && data.suggestionType == 0);
          this.documentsArray = autoCompleteResponse.filter(data => !!data.item && data.suggestionType == 1);
        }
      });
  }
}
  
}
